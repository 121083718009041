import { Injectable } from '@angular/core';
import { IInvoice, IInvoiceDTO } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedInvoiceFactory {
  toRequest(order: IInvoice): IInvoiceDTO {
    const { lines, mainInfo } = order;

    return {
      ...mainInfo,
      lines
    } as unknown as IInvoiceDTO;
  }

  fromResponse(order: IInvoiceDTO): IInvoice {
    const { lines, ...mainInfo } = order;

    const result: IInvoice = {
      mainInfo: mainInfo as Omit<IInvoiceDTO, 'lines'>,
      lines
    };

    return result;
  }
}
