import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { ICollectionResponse } from '../abstracts/http/collection-response.interface';
import { IPurchaseOrdersListResponse } from '../interfaces/purchase-orders.interface';
import { IDocumentTransition } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedPurchaseOrdersService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('PurchaseOrders');
  }

  getPurchaseOrders(params: object): Observable<ICollectionResponse<IPurchaseOrdersListResponse>> {
    return this.get<ICollectionResponse<IPurchaseOrdersListResponse>>(params);
  }

  getAvailableLineStatusTransitions(currentStatusId: number | string) {
    return this.get<IDocumentTransition[]>({ currentStatusId }, 'availableLineStatusTransitions');
  }
}
