import { Observable, iif, map, mapTo, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';
import { EInventoryStatuses } from '../enums';
import { IDefaultOwner } from '../interfaces';
import { IdValue } from '../types';
import { Cache } from '../decorators';

@Injectable({
  providedIn: 'root'
})
export class ERPSettingsService extends BaseHttpService {
  private _defaultLocation = '';

  constructor(readonly httpClient: HttpClient) {
    super('settings');
  }

  @Cache()
  getDefaultLocation(params = {}) {
    return this.get<IdValue>(params, 'location');
  }

  getDefaultOwner(params = {}): Observable<IDefaultOwner> {
    return this.get<IDefaultOwner>(params, `owner`);
  }

  getFTZLocation(): Observable<IdValue<EInventoryStatuses>> {
    return this.get<IdValue<EInventoryStatuses>>({}, 'inventoryStatus/ftz');
  }

  get defaultLocation(): string {
    return this._defaultLocation;
  }

  onLoadDefaultLocation(): Observable<string> {
    if (this._defaultLocation) {
      return of(this._defaultLocation).pipe(
        tap((location: string) => (location ? (this._defaultLocation = location) : null))
      );
    }
    return this.getDefaultLocation().pipe(
      map((location: IdValue | null) => location?.value ?? ''),
      tap((location: string) => (location ? (this._defaultLocation = location) : null)),
      mapTo('')
    );
  }
}
