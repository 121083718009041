import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ERPAuthService, Memoise } from '@erp/shared';
import { DashboardControlArgs } from 'devexpress-dashboard/common';
import { ERPApiConfigService, PageTitleService } from '@erp/core';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EventMessage, EventType } from '@azure/msal-browser';
import { merge, of, Subject } from 'rxjs';
import { GridHeaderFilterExtension } from './report-header.extension';
import { DxDashboardControlComponent } from 'devexpress-dashboard-angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'erp-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.scss']
})
export class ERPReportComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  @ViewChild('dashboardControl', { static: false }) dashboardControl: DxDashboardControlComponent;
  @ViewChild('refreshButton', { static: false }) refreshButton: TemplateRef<HTMLButtonElement>;
  tokenPending$ = new Subject<null>();
  headers: { Authorization: string };
  id: string;
  name: string;

  constructor(
    private readonly authService: ERPAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private $apiConfigService: ERPApiConfigService,
    private titleService: PageTitleService
  ) {}

  headers$ = merge(
    this.route.params.pipe(switchMap(_ => this.authService.getToken().pipe(take(1)))),
    this.tokenPending$
  ).pipe(
    map((token: string | null) => {
      if (!token) {
        return token;
      } else {
        return { Authorization: `bearer ${token}` };
      }
    })
  );

  ngOnInit() {
    const token = this.authService.getCookie('JWT-Cookie');
    this.headers = { Authorization: `bearer ${token}` };
  }

  id$ = this.route.params.pipe(
    takeUntil(this.destroy$),
    tap(() => {
      this.titleService.setTitle(this.route.snapshot?.queryParams?.name ?? '');
    }),
    map(params => params['id'])
  );

  requestComplete = (jqXHR: JQueryXHR) => {
    if ([401, 403].includes(jqXHR.status)) {
      this.tokenPending$.next(null);
      this.authService
        .getToken(true)
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
            onSameUrlNavigation: 'reload'
          });
        });
    }
  };

  @Memoise()
  get endpoint(): string {
    const endpoint = `${this.$apiConfigService.domain}/gateway/api/dashboard`;

    return endpoint;
  }

  onDashboardInitialized(args: DashboardControlArgs) {
    const dashboardControl = args.component;

    dashboardControl.registerExtension(new GridHeaderFilterExtension(dashboardControl));

    const state = dashboardControl.getDashboardState();
    const reverseHour = -60;
    const updatedState = JSON.stringify({
      Parameters: {
        ...JSON.parse(state).Parameters,
        TimeZoneOffset: '' + new Date().getTimezoneOffset() / reverseHour
      }
    });

    dashboardControl.setDashboardState(updatedState);
  }

  onRefreshDashboard() {
    this.dashboardControl.instance.reloadData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.tokenPending$.complete();
  }
}
